<template>
  <v-dialog
    v-model="dialog"
    :max-width="reorganizeSlotsOnly ? '500px' : '250px'"
    @click:outside="reorganizeSlotsOnly = false"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <v-btn
          color="#0ca344"
          :text="!allowedDate"
          :outlined="allowedDate"
          :disabled="!allowedDate"
          class="flex-grow-1"
          v-bind="attrs"
          v-on="on"
          @click="reorganizeSlotsOnly = false"
        >
          {{ moment(date).format('DD') }}
        </v-btn>
        <v-btn
          v-if="allowedDate"
          color="#00695C"
          outlined
          v-bind="attrs"
          v-on="on"
          @click="reorganizeSlotsOnly = true"
        >
          <v-icon>mdi-history</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card v-if="reorganizeSlotsOnly">
      <v-card-title class="text-center d-block red--text">
        Are you sure to proceed?
        <br>
        This action can not be undone
      </v-card-title>

      <v-divider class="ma-2" />

      <v-card-actions class="text-center">
        <v-spacer />
        <v-btn
          color="white"
          name="Cancel"
          class="text-right mr-10"
          @click="onNo()"
        >
          NO
        </v-btn>
        <v-btn
          color="red"
          name="Add"
          class="text-right ml-10"
          @click="onYes()"
        >
          YES
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="text-h5">
          Set new slot limit
        </span>
      </v-card-title>

      <v-card-text class="px-8 mt-8">
        <v-form
          ref="appUserForm"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="newLimit"
            type="number"
            label="Insert new limit"
            append-outer-icon="mdi-plus-thick"
            prepend-icon="mdi-minus-thick"
            :rules="limit_rule"
            @click:append-outer="newLimit += 1"
            @click:prepend="newLimit -= 1"
          />
        </v-form>
        <v-switch
          v-model="applyReorganizeSlots"
          inset
          label="Reorganize slots"
          color="success"
          hide-details
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getRegExp } from '@/formRules.js';
import moment from 'moment-business-days';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    allowedDate: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      applyReorganizeSlots: true,
      reorganizeSlotsOnly: false,
      overlay: false,
      dialog: false,
      valid: true,
      newLimit: 0,
      limit_rule: [
        ...getRegExp('positive_numbers')
      ]
    };
  },
  computed: {
    ...mapState(['ticketing'])
  },
  watch: {
    $route() {},
    item: {
      handler(newValue) {
        this.newLimit = newValue.max_slots;

        if (newValue.total_slots !== 0) {
          this.limit_rule = [
            v => (!isNaN(parseFloat(v)) && v >= newValue.total_slots + 1) || `Slots should not be less than ${this.item.total_slots + 1}`
          ];
        }
      }
    }
  },
  methods: {
    ...mapActions(['editEvasionLimitSlotsAction', 'reorganizeSlots']),
    async save() {
      this.overlay = true;
      const isAfterToday = moment(this.date, 'YYYY-MM-DD').isAfter(moment(), 'day');
      const params = {
        evasion_limit_date: this.date,
        slots: this.newLimit,
        slots_to_reorganize: this.applyReorganizeSlots && isAfterToday ? await this.reorganizeSlots(this.date) : []
      };
      this.dialog = false;
      await this.editEvasionLimitSlotsAction(params);
      this.overlay = false;
      this.$emit('get-all-slots');
    },
    close() {
      this.newLimit = this.item.max_slots;
      this.dialog = false;
    },
    async onYes() {
      this.overlay = true;
      const params = {
        evasion_limit_date: this.date,
        slots_to_reorganize: await this.reorganizeSlots(this.date)
      };
      this.dialog = false;
      await this.editEvasionLimitSlotsAction(params);
      this.overlay = false;
      this.reorganizeSlotsOnly = false;
    },
    onNo() {
      this.dialog = false;
      this.reorganizeSlotsOnly = false;
    }
  }
};
</script>
